import React, { useMemo } from "react";
import { Trans } from "react-i18next";
import { styled } from "goober";
import { Select } from "clutch/src/Select/Select.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { writeSettings } from "@/app/actions.mjs";
import themes from "@/util/themes.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const opacs = ["75", "50", "25", "15"];

const ColorPage = () => {
  const {
    settings: { theme },
  } = useSnapshot(readState);

  const themeOptions = useMemo(() => {
    return Object.values(themes).map((th) => ({
      text: [th.nameKey, th.nameFallback] as const,
      value: th.key,
    }));
  }, []);

  return (
    <PageContainer>
      <Trans parent="span">
        Theme:{" "}
        <Select
          selected={theme}
          options={themeOptions}
          onChange={(value) => writeSettings(["theme"], value)}
        />
      </Trans>
      <Container>
        {Array.from({ length: 11 }).map((_, i) => (
          <React.Fragment key={i}>
            <Tile $shade={`${i}`}>{`Shade ${i}`}</Tile>
            <>
              {opacs.map((o) => {
                return (
                  <Tile key={o} $shade={`${i}-${o}`}>
                    {o}
                  </Tile>
                );
              })}
            </>
          </React.Fragment>
        ))}
      </Container>
    </PageContainer>
  );
};

const PageContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-2);
  width: 100%;
  max-width: var(--sp-container);
`;

const Container = styled("div")`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(${opacs.length + 1}, minmax(0, 1fr));
`;

const Tile = styled("div")<{ $shade: string }>`
  aspect-ratio: 1;
  background: var(${(_) => `--shade${_.$shade}`});
  white-space: nowrap;

  display: grid;
  place-content: center;
`;

export function meta() {
  return {
    title: [null, "Colors"],
    description: [null, "Colors"],
  };
}

export default ColorPage;
